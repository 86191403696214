(function() {
    angular.module('EntrakV5').controller('codeHandlerController', codeHandlerController);

    //for ms sign in
    function codeHandlerController($scope, $rootScope, $state, Api, Service, REDIRECT_LANDLORD_URL, REDIRECT_PORTAL_URL, IS_LOCAL) {
        console.log('codeHandlerController');

        //stateParams dont work, must use window.location.search becuase the url format is different
        var params = {};
        if (window.location.search){
            window.location.search.substr(1).split("&").forEach(function(p){
                var tmp = p.split("=");
                params[tmp[0]] = tmp[1];
            });
        }

        if (params.error === "interaction_required") { // silent signIn failed
          Service.deleteCookie("et_method");
          Service.deleteCookie("et_it");
          Service.deleteCookie("et_rt");
          $state.go("login", { landlord: null });
        } else if (params.code) { // normal signIn success
          var redirectUrl = params.state === 'true' ? REDIRECT_LANDLORD_URL : REDIRECT_PORTAL_URL;
          redirectUrl = redirectUrl.replace("${langCode}", $rootScope.langCode);
          Api.getTokenByMSCode(params.code, function(res){
              Api.loginWithOAuth(res.id_token).then(function(r){
                Api.initApolloClient("microsoft", res.id_token);
                Api.logSignIn();
                Service.setCookie("et_method", "microsoft");
                Service.setCookie("et_it", res.id_token);
                Service.setCookie("et_rt", res.refresh_token);
                if (IS_LOCAL)
                  redirectUrl = `${redirectUrl}?m=microsoft&it=${res.id_token}&rt=${res.refresh_token}`;
                window.location = redirectUrl;
              }, function(e){
                  console.error(e);
                  $state.go("login", { landlord: null });
              });
          }, function(err){
              console.error(err);
              $state.go("login", { landlord: null });
          });
        } else {
          $state.go("login", { landlord: null });
        }


        $scope.$on('$destroy', function() {
            console.log("codeHandlerController destroy");
        });
    }
})();
